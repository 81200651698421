/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable jsx-a11y/control-has-associated-label*/
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import videojs from 'video.js';
import classNames from 'classnames';

import { isEmpty } from 'lodash';

import { isValidMuxUrl } from 'src/utils/url-utils';

import 'video.js/dist/video-js.css';
import 'src/styles/video-player.scss';

// video.js player from the docs: https://github.com/videojs/video.js/blob/master/docs/guides/react.md
const VideoPlayer = ({ src, options, events, preview }) => {
    let player,
        isMuted = false;

    const [id, setId] = useState(0);

    /**
     * Video.js has a fluid mode that keeps the player sized to a particular aspect ratio.
     * By default, fluid mode will use the intrinsic size of the video once loaded but you
     * can change it with classes or with the aspectRatio option.
     */
    const defaultOptions = {
        autoplay: true,
        controls: true,
        fluid: true,
        aspectRatio: '16:9',
        preload: 'auto',
        html5: {
            hls: {
                enableLowInitialPlayist: true,
                smoothQualityChange: true,
            },
        },
    };

    useEffect(() => {
        // componentDidMount

        return () => {
            // componentDidUnmount
            disposePlayer();
        };
    }, []);

    /**
     * VideoJS uses the id of the <video> DOM element to initialize.
     * VideoJS removes the <video> tag from the DOM when the player is disposed.
     * So, each time the video player is opened, it recreates the DOM with a new <video> tag that has a unique ID.
     */
    useEffect(() => {
        if (isValidMuxUrl(src)) {
            createPlayer();

            // hide scrollbars when video player is open
            document.body.style.overflow = 'hidden';
        } else {
            // Next time the videoPlayer is opened, it'll have a unique id
            setId(id + 1);

            // show scrollbars when video player is closed
            document.body.style.overflow = 'visible';
        }
    }, [src]);

    const getVideoId = () => `vjs-${id}`;

    const createPlayer = () => {
        if (player || isEmpty(src)) {
            return;
        }

        initPlayer();
        initPlayerUI();
        initPlayerEvents();
    };

    const disposePlayer = () => {
        if (player) {
            player.dispose();
        }
    };

    /**
     * Can be used to fast forward, rewind, or jump to a certain time.
     *
     * @param {*} timeInSeconds Positive or Negative time measured in seconds
     */
    const seek = (timeInSeconds) => {
        /** ToDo: compare to end of video/video length */
        player.currentTime(Math.max(0, player.currentTime() + timeInSeconds));
    };

    const initPlayer = () => {
        const videoId = getVideoId();

        // instantiate Video.js player using the id of the <video> element
        if (document.getElementById(videoId)) {
            player = videojs(videoId, {
                ...defaultOptions,
                ...options,
                sources: [src],
            });
        }
    };

    const initPlayerUI = () => {
        if (!player) {
            return;
        }

        /**
         * Get video.js components / component classes
         * @see https://docs.videojs.com/tutorial-components.html
         * @see https://docs.videojs.com/component
         */
        const Component = videojs.getComponent('Component');
        const Button = videojs.getComponent('Button');

        /**************************
         * PLAYER UI ELEMENTS
         **************************/

        /**
         * Remove 'BigPlayButton' that is on the top left by default
         */
        player.removeChild('BigPlayButton');

        /**
         * Add a CloseButton to top right that triggers a 'close' event
         */
        const closeBtn = player.addChild('CloseButton');

        closeBtn.on('close', () => {
            disposePlayer();
        });
        player.on('userinactive', () => {
            closeBtn.addClass('vjs-hidden');
        });
        player.on('useractive', () => {
            closeBtn.removeClass('vjs-hidden');
        });

        /**************************
         * CONTROL BAR UI ELEMENTS
         **************************/

        const controlBar = player.getChild('ControlBar');

        /**
         * Remove default elements from control Panel:
         * Play/Pause, PiP, Volume, CC Buton, and FullScreen Toggle
         */
        controlBar.removeChild('PlayToggle');
        controlBar.removeChild('PictureInPictureToggle');
        controlBar.removeChild('VolumePanel');
        controlBar.removeChild('SubsCapsButton');
        controlBar.removeChild('FullscreenToggle');

        /**
         * Create Custom `SecondaryControls` Component for layout & positioning
         * that wraps the Volume Panel, CC Button, and FullScreen Toggler
         */
        const secondaryControlsComponent = videojs.extend(Component, {
            constructor: function () {
                Component.apply(this, arguments);
                this.addClass('vjs-secondary-controls');
            },
        });

        videojs.registerComponent(
            'SecondaryControls',
            secondaryControlsComponent
        );

        const secondaryControls = controlBar.addChild('SecondaryControls');

        secondaryControls.addChild('VolumePanel');
        secondaryControls.addChild('SubsCapsButton');
        secondaryControls.addChild('FullscreenToggle');
        player.removeChild('TextTrackSettings');

        /**
         * Create Custom `PrimaryControls` Component for layout & positioning
         * that wraps the Rewind, Play/Pause, and FF Buttons
         */
        const primaryControlsComponent = videojs.extend(Component, {
            constructor: function () {
                Component.apply(this, arguments);
                this.addClass('vjs-primary-controls');
            },
        });

        videojs.registerComponent('PrimaryControls', primaryControlsComponent);

        const primaryControls = controlBar.addChild('PrimaryControls');

        const forwardButton = new Button(player, {
            clickHandler: () => {
                seek(15);
            },
        });
        forwardButton.addClass('vjs-forward-button');

        const rewindButton = new Button(player, {
            clickHandler: () => {
                seek(-15);
            },
        });
        rewindButton.addClass('vjs-rewind-button');

        primaryControls.addChild(rewindButton);
        primaryControls.addChild('PlayToggle');
        primaryControls.addChild(forwardButton);
    };

    const initPlayerEvents = () => {
        /**
         * ToDo: Event Analytics go here
         */
        if (player) {
            player.ready(() => {
                console.log(`Player ready`);

                if (events.onReady) {
                    events.onReady(player);
                }
            });

            player.on('play', () => {
                console.log(`PLAY: ${player.currentTime()}`);

                if (events.onPlay) {
                    events.onPlay(player);
                }
            });

            player.on('pause', () => {
                console.log(`PAUSE: ${player.currentTime()}`);

                if (events.onPause) {
                    events.onPause(player);
                }
            });

            player.on('dispose', () => {
                console.log(`DISPOSE/CLOSE`);

                if (events.onClose) {
                    events.onClose(player);
                }
            });

            player.on('error', (err) => {
                console.log('ERROR');

                if (events.onError) {
                    events.onError(player, err);
                }
            });

            player.on('loadeddata', () => {
                console.log('LOADED');

                if (events.onLoad) {
                    events.onLoad(player);
                }
            });

            player.on('ended', () => {
                console.log('ENDED');

                if (events.onEnd) {
                    events.onEnd(player);
                }
            });

            player.on('progress', () => {
                //console.log('PROGRESS');

                if (events.onProgress) {
                    events.onProgress(player);
                }
            });

            player.on('seeking', () => {
                console.log('SEEKING');

                if (events.onSeeking) {
                    events.onSeeking(player);
                }
            });

            player.on('seeked', () => {
                console.log('SEEKED');

                if (events.onSeeked) {
                    events.onSeeked(player);
                }
            });

            player.on('volumechange', () => {
                const volume = player.volume();

                console.log(`VOLUMECHANGE: ${volume}`);

                if (volume === 0) {
                    player.trigger('mute');
                    isMuted = true;
                } else if (isMuted) {
                    player.trigger('unmute');
                    isMuted = false;
                }

                if (events.onVolumeChange) {
                    events.onVolumeChange(player);
                }
            });

            player.on('mute', () => {
                console.log('Mute');

                if (events.onMute) {
                    events.onMute();
                }
            });

            player.on('unmute', () => {
                console.log('Unmute');

                if (events.onUnmute) {
                    events.onUnmute();
                }
            });
        }
    };

    const containerClass = classNames('video-container', {
        preview: preview,
        open: isValidMuxUrl(src),
    });

    /**
     * Wrap the player in a div with a `data-vjs-player` attribute
     * and videojs will re-use that div for the player element and
     * that video element for the tech.
     *
     * @see https://github.com/videojs/video.js/pull/3856
     * @see https://github.com/videojs/video.js/issues/3816#issuecomment-268907728
     **/
    const videoHtml = `
        <div data-vjs-player>
            <video id="${getVideoId()}" class="video-js"></video>
        </div>
    `;

    return (
        <div
            className={containerClass}
            dangerouslySetInnerHTML={{ __html: videoHtml }}
        ></div>
    );
};

VideoPlayer.propTypes = {
    src: PropTypes.string,
    options: PropTypes.object,
    events: PropTypes.object,
    preview: PropTypes.bool,
};

VideoPlayer.defaultProps = {
    options: {},
    events: {},
    preview: false,
};

export default VideoPlayer;
